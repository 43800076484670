<template>
  <div>
    <b-row>
      <b-col
        md="12"
        xl="12"
      >
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              md="6"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :text="kandidatData.inicijali"
                  variant="light-primary"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ kandidatData.ime }} {{ kandidatData.prezime }}
                    </h4>
                    <span class="card-text">{{ kandidatData.email }}</span>
                  </div>
                  <!-- <div class="d-flex flex-wrap">
                    <b-button
                      :to="{ name: 'apps-users-edit', params: { id: userData.id } }"
                      variant="primary"
                    >
                      Edit
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      class="ml-1"
                    >
                      Delete
                    </b-button>
                  </div> -->
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar
                    variant="light-primary"
                    rounded
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ kandidatData.datum_polozenog_ti_prikaz }}
                    </h5>
                    <small>{{ $t('DatumPolozenogTI') }}</small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="InfoIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ kandidatData.kategorija }}
                    </h5>
                    <small>{{ $t('Kategorija') }}</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('KorisnickoIme') }}</span>
                  </th>
                  <td class="pb-50">
                    {{ kandidatData.korisnickoime }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('Status') }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ kandidatData.status }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('JMBG') }}</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ kandidatData.jmbg }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="FlagIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('MestoPrebivalista') }}</span>
                  </th>
                  <td class="pb-50">
                    {{ kandidatData.mesto_prebivalista }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="FlagIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('Adresa') }}</span>
                  </th>
                  <td class="pb-50">
                    {{ kandidatData.adresa }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('Telefon') }}</span>
                  </th>
                  <td>
                    {{ kandidatData.telefon }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">{{ $t('PosedujeKategorije') }}</span>
                  </th>
                  <td>
                    <span
                      v-for="(kategorija, index) in kandidatData.poseduje_kategorije"
                      :key="index"
                    >
                      {{ kategorija.kategorija }}
                    </span>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="match-height"
    >
      <b-col
        lg="6"
        md="6"
      >
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-header>
            <b-card-title>
              {{ $t('Uslovi') }}
            </b-card-title>
          </b-card-header>

          <b-card-body>
            <div
              class="transaction-item"
            >
              <b-media
                no-body
              >
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    :variant="kandidatData.potrebna_pomagala_prikaz === 'Potrebna' ? 'danger' : 'success'"
                  >
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6
                    class="transaction-title"
                  >
                    {{ $t('PotrebnaPomagala') }}
                  </h6>
                  <small>
                    {{ $t('KandidatPomagalaPoruka') }}
                  </small>
                </b-media-body>
              </b-media>
              <div
                :class="kandidatData.potrebna_pomagala_prikaz === 'Potrebna' ? 'font-weight-bolder text-danger' : ''"
              >
                {{ $t(kandidatData.potrebna_pomagala_prikaz) }}
              </div>
            </div>
            <div
              class="transaction-item"
            >
              <b-media
                no-body
              >
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    :variant="kandidatData.status_teorisjkog_ispita === 'istekao' ? 'danger' : kandidatData.status_teorisjkog_ispita === 'istice' ? 'warning' : 'success'"
                  >
                    <feather-icon
                      size="18"
                      icon="BookIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6
                    class="transaction-title"
                  >
                    {{ $t('TeorijskiIspit') }}
                  </h6>
                  <small>
                    {{ $t(kandidatData.status_teorijskog_ispita) }}
                  </small>
                </b-media-body>
              </b-media>
              <div
                :class="`font-weight-bolder text-${kandidatData.status_teorisjkog_ispita === 'istekao' ? 'danger' : kandidatData.status_teorisjkog_ispita === 'istice' ? 'warning' : 'success'}`"
              >
                {{ kandidatData.datum_vazenja_ti_prikaz }}
              </div>
            </div>
            <!-- <div
              class="transaction-item"
            >
              <b-media
                no-body
              >
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    :variant="vozackaVariant"
                  >
                    <feather-icon
                      size="18"
                      icon="TruckIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6
                    class="transaction-title"
                  >
                    {{ $t('OstaloCasovaPrakticneObuke') }}
                  </h6>
                  <small>{{ $t('PreostaliCasovi') }}</small>
                </b-media-body>
              </b-media>
              <div
                :class="`font-weight-bolder text-${lekarskoVariant}`"
              >
                10
              </div>
            </div> -->
            <div
              class="transaction-item"
            >
              <b-media
                no-body
              >
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    :variant="lekarskoVariant"
                  >
                    <feather-icon
                      size="18"
                      icon="HeartIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6
                    class="transaction-title"
                  >
                    {{ $t('DatumVazenjaLekarskog') }}
                  </h6>
                  <small>{{ podnaslovLekarsko }}</small>
                </b-media-body>
              </b-media>
              <div
                :class="`font-weight-bolder text-${lekarskoVariant}`"
              >
                {{ kandidatData.datum_vazenja_lekarskog !== '' ? kandidatData.datum_vazenja_lekarskog : $t('LekarskoNijeUneto') }}
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-body
            :title="$t('Instruktori')"
          >
            <b-list-group>
              <b-list-group-item
                v-for="(instruktor, index) in kandidatData.instruktori"
                :key="index"
              >
                {{ instruktor.ime_prezime }}
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
          <b-card-body
            :title="$t('Predavaci')"
          >
            <b-list-group>
              <b-list-group-item
                v-for="(instruktor, index) in kandidatData.predavaci"
                :key="index"
              >
                {{ instruktor.ime_prezime }}
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          :title="$t('Napomena')"
        >
          {{ kandidatData.napomena }}
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BAvatar, BRow, BCol, BMedia, BMediaAside, BMediaBody, BCardHeader, BCardTitle, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      userData: {},
      kandidatData: {},
      podnaslovLekarsko: this.$t('LekarskoJeURedu'),
      lekarskoVariant: 'success',
      podnaslovTeorijskiIspit: this.$t('TeorijskiIspitJeURedu'),
      teorijskiIspitVariant: 'success',
      pomagalaVariant: 'success',
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get(`/instruktori/kandidati/prikazi/${this.$route.params.id}`).then(response => {
        this.kandidatData = response.data
        if (this.kandidatData.vazi_lekarsko === false) {
          this.podnaslovLekarsko = this.$t('LekarskoNeVazi')
          this.lekarskoVariant = 'danger'
        }
        if (this.kandidatData.istice_lekarsko === true) {
          this.podnaslovLekarsko = this.$t('IsticeLekarsko')
          this.lekarskoVariant = 'warning'
        }

        if (this.kandidatData.vazi_teorijski_ispit === false) {
          this.podnaslovTeorijskiIspit = this.$t('TeorijskiIspitNeVazi')
          this.teorijskiIspitVariant = 'danger'
        }
        if (this.kandidatData.istice_teorijski_ispit === true) {
          this.podnaslovTeorijskiIspit = this.$t('IsticeTeorijskiIspit')
          this.teorijskiIspitVariant = 'warning'
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>

</style>
